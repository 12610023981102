<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/admin/commiteevecreport" class="mr-2">
        <span>รายงานการแต่งตั้งคณะกรรมการตรวจสอบ </span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>

      <v-btn to="/admin/commiteevecpro">
        <span>รายชื่อคณะกรรมการ</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงาน คณะกรรมการตรวจสอบศักยภาพของผู้ประสงค์ขอย้าย ตำแหน่งครู (การย้ายกรณีปกติ)"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-btn color="info" 
              :href="'#/admin/print_commiteevecreport/' + periods.period_vecprovince_year + '/' + periods.period_vecprovince_times" target="_blank"             
              >
                <v-icon>mdi-printer</v-icon>
                พิมพ์ข้อมูลการรายงาน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="commiteevecreports"
          :search="search"
        >
          <template v-slot:item.commiteevecreportYear="{ item }">
            <div v-if="item.countPerson === '0'">
              <v-chip color="primary">
                ไม่มีผู้ยืนย้าย
              </v-chip>
            </div>
            <div v-else>
              <span v-if="item.commiteevecreportYear">
                {{ parseInt(item.commiteevecreportYear) + 543 }}
              </span>
              <span v-else>
                สอจ.ยังไม่ได้บันทึกรายการ
              </span>
            </div>
          </template>

          <template v-slot:item.commiteevecreportDateTime="{ item }">
            {{
              item.commiteevecreportDateTime | moment("add", "543 years")
                | moment("D MMMM YYYY")              
            }}
          </template>

          <template v-slot:item.commiteevecreportFile="{ item }">
            <v-btn
              v-if="item.commiteevecreportFile"
              text=""
              color="info"
              :href="'/HRvecfiles/' + item.commiteevecreportFile"
              target="_blank"
            >
              <v-icon>
                mdi-printer
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.commiteevecreportFileDate="{ item }">
            {{
              item.commiteevecreportFileDate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.action="{ item }">
            <div v-if="item.countPerson === '0'"></div>
            <div v-else>
              <v-icon
                color="red"
                @click.stop="
                  actionDelete(
                    item.province_ID,
                    item.commiteevecreportYear,
                    item.commiteevecreportTime
                  )
                "
              >
                mdi-delete-circle
              </v-icon>
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addcommiteevecreportdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addcommiteevecreportdialog"
          persistent
          max-width="50%"
          overlay-opacity="0.6"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-clipboard-text"
              title="เพิ่มข้อมูล คณะกรรมการ"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="addcommiteevecreportform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-autocomplete
                            :items="personnel_temporarys"
                            item-text="frist_names"
                            item-value="id_card"
                            v-model="
                              addcommiteevecreport.commiteevecreportIdCard
                            "
                            prepend-icon="mdi-calendar"
                            dense
                            label="รหัสบัตรประชาชน"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                            @change="id_cardSearch()"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-select
                            v-model="
                              addcommiteevecreport.commiteevecreportPosition
                            "
                            :items="commiteevecreport"
                            item-text="text"
                            item-value="value"
                            prepend-icon="mdi-access-point"
                            dense
                            label="ตำแหน่ง : ประธานกรรมการ / กรรมการ"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="personnel_temporaryschecks.frist_names"
                        >
                          <v-alert dense text type="success">
                            {{ personnel_temporaryschecks.frist_names }} ตำแหน่ง
                            {{ personnel_temporaryschecks.position_name }}
                            <br />
                            {{ personnel_temporaryschecks.college_name }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-col cols="12" lg="12" class="text-right">
                      <v-btn
                        v-if="personnel_temporaryschecks.frist_names"
                        large
                        color="success"
                        @click.stop="addcommiteevecreportSubmit()"
                        rounded
                      >
                        <v-icon dark>mdi-content-save</v-icon
                        >&nbsp;&nbsp;บันทึก</v-btn
                      >
                      <v-btn
                        large
                        color="warning"
                        @click.stop="addcommiteevecreportdialog = false"
                        rounded
                      >
                        <v-icon dark>mdi-close</v-icon>ยกเลิก
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletecommiteevecreportdialog -->
      <v-layout>
        <v-dialog
          v-model="deletecommiteevecreportdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ยกเลิกการรายงาน"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="deletecommiteevecreportform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันการยกเลิกการรายงาน :
                      <h3>
                        สำนักงานอาชีวศึกษาจังหวัด
                        {{ editcommiteevecreport.province_name }}
                      </h3>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecommiteevecreportdialog = false">
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletecommiteevecreportubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editcommiteevecreportdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editcommiteevecreportdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลการศึกษา"
              class="px-5 py-3 "
            ></base-material-card>
            <v-card-text>
              <v-form ref="editcommiteevecreportform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          ปี
                          {{ periods.period_vecprovince_year }}
                          ครั้งที่ {{ periods.period_vecprovince_times }} <br />
                          กรรมการ
                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_teacher'
                            "
                            >พิจารณาย้ายสายการสอน</span
                          >

                          <span
                            v-if="
                              periods.period_vecprovince_type ===
                                'discuss_manage'
                            "
                            >พิจารณาย้ายสายบริหาร</span
                          >
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-select
                            v-model="
                              editcommiteevecreport.commiteevecreportPosition
                            "
                            :items="commiteevecreport"
                            item-text="text"
                            item-value="value"
                            prepend-icon="mdi-access-point"
                            dense
                            label="ตำแหน่ง : ประธานกรรมการ / กรรมการ"
                            request
                            :rules="[v => !!v || '']"
                            rounded
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          v-if="personnel_temporaryschecks.frist_names"
                        >
                          <v-alert dense text type="success">
                            {{ personnel_temporaryschecks.frist_names }} ตำแหน่ง
                            {{ personnel_temporaryschecks.position_name }}
                            <br />
                            {{ personnel_temporaryschecks.college_name }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editcommiteevecreportdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editcommiteevecreportSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model Comfirmcommiteevecreportdialog -->
      <v-layout>
        <v-dialog
          v-model="Comfirmcommiteevecreportdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="primary"
              icon="mdi-account-check"
              title="ยืนยันรายชื่อคณะกรรมการ"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text class="">
              <v-form ref="comfirmcommiteevecreportform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      ยืนยันรายชื่อคณะกรรมการ :

                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="headers"
                        :items="commiteevecreports"
                        :search="search"
                      >
                        <template v-slot:item="{ item, index }">
                          <tr>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              {{ item.user_lastname }}
                            </td>
                            <td class="text-center">
                              {{ item.commiteevecreportYear }}
                            </td>
                            <td>{{ item.commiteevecreportTime }}</td>
                            <td>
                              {{
                                item.title_s +
                                  "" +
                                  item.frist_name +
                                  " " +
                                  item.last_name
                              }}
                            </td>
                            <td class="text-center">
                              <span
                                v-if="item.commiteevecreportPosition === '1'"
                              >
                                ประธานกรรมการ</span
                              >
                              <span
                                v-if="item.commiteevecreportPosition === '2'"
                              >
                                กรรมการ ลำดับที่ 1</span
                              >
                              <span
                                v-if="item.commiteevecreportPosition === '3'"
                              >
                                กรรมการ ลำดับที่ 2</span
                              >
                              <span
                                v-if="item.commiteevecreportPosition === '4'"
                              >
                                กรรมการ ลำดับที่ 3</span
                              >
                              <span
                                v-if="item.commiteevecreportPosition === '5'"
                              >
                                กรรมการ ลำดับที่ 4</span
                              >
                              <span
                                v-if="item.commiteevecreportPosition === '6'"
                              >
                                เลขานุการ</span
                              >
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="yellow"
                                @click.stop="
                                  commiteevecreportEdit(
                                    item.commiteevecreportproviceID,
                                    item.commiteevecreportYear,
                                    item.commiteevecreportTime,
                                    item.commiteevecreportIdCard
                                  )
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </td>
                            <td class="text-center">
                              <v-icon
                                color="red"
                                @click.stop="
                                  commiteevecreportDelete(
                                    item.commiteevecreportproviceID,
                                    item.commiteevecreportYear,
                                    item.commiteevecreportTime,
                                    item.commiteevecreportIdCard
                                  )
                                "
                              >
                                mdi-delete
                              </v-icon>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <div class="red--text">
                *เมื่อกดปุ่มบันทึก ยืนยันรายการ ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ
                ได้ กรุณาตรวจสอบให้ถูกต้องเรียบร้อยก่อนการบันทึกยืนยันรายการ
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="Comfirmcommiteevecreportdialog = false">
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                large
                color="primary"
                @click.stop="confirmcommiteevecreportubmit()"
                dark
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก ยืนยันรายการ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addcommiteevecreportdialog: false,
      editcommiteevecreportdialog: false,
      deletecommiteevecreportdialog: false,
      Comfirmcommiteevecreportdialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
       
        { text: "สอจ.ID", align: "left", value: "province_ID" },
        { text: "สอจ.", align: "left", value: "province_name" },
        { text: "จำนวนผู้ยืนย้าย", align: "center", value: "countPerson" },
        { text: "ปี", align: "center", value: "commiteevecreportYear" },
        { text: "ครั้งที่", align: "center", value: "commiteevecreportTime" },
        {
          text: "วันที่รายงาน",
          align: "center",
          value: "commiteevecreportDateTime"
        },
        { text: "ไฟล์แนบ", align: "center", value: "commiteevecreportFile" },
        {
          text: "วันที่แนบไฟล์",
          align: "center",
          value: "commiteevecreportFileDate"
        },
        { text: "ดำเนินการ", align: "center", value: "action" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      commiteevecreport: [],
      addcommiteevecreport: {},
      editcommiteevecreport: [],
      commiteevecreports: [],
      commiteevecreport_sub: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      commiteevecreport: [
        { text: "ประธานกรรมการ", value: "1" },
        { text: "กรรมการ ลำดับที่ 1", value: "2" },
        { text: "กรรมการ ลำดับที่ 2", value: "3" },
        { text: "กรรมการ ลำดับที่ 3", value: "4" },
        { text: "กรรมการ ลำดับที่ 4", value: "5" },
        { text: "เลขานุการ", value: "6" }
      ],
      personnel_temporaryschecks: [],
      periods: [],
      personnel_temporarys: [],
      user: [],
      commiteevecreportStatusC: {},
      commiteevecreportStatusRP: {},
      commiteevecreports: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB"
      ],
      updateFilecommiteevecreports: {},
      documentsLinkfiles: null,
      commiteevecpros: {}
    };
  },

  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("admin.php", {
      user_name: userSession.user_name,
      provice: "check",
      ApiKey: "HRvec2021"
    });
    this.user = result.data;
    await this.periodQuery();
    await this.commiteevecreportsQueryAll();
  },

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovince_type: "discuss_teacher"
      });
      this.periods = result_period.data;
    },

    async commiteevecreportsQueryAll() {
      this.loading = true;    
      let result = await this.$http
        .post("commiteevecreport.php", {
          ApiKey: this.ApiKey,
          commiteevecreportYear: this.periods.period_vecprovince_year,
          commiteevecreportTime: this.periods.period_vecprovince_times
        })
        .finally(() => (this.loading = false));
      this.commiteevecreports = result.data;
      ;
    },

    //DELETE data
    async actionDelete(
      commiteevecreportproviceID,
      commiteevecreportYear,
      commiteevecreportTime
    ) {
      let result = await this.$http.post("commiteevecreport.php", {
        ApiKey: this.ApiKey,
        commiteevecreportproviceID: commiteevecreportproviceID,
        commiteevecreportYear: commiteevecreportYear,
        commiteevecreportTime: commiteevecreportTime
      });
      this.editcommiteevecreport = result.data;
      this.deletecommiteevecreportdialog = true;  
    },

    async deletecommiteevecreportubmit() {
      if (this.$refs.deletecommiteevecreportform.validate()) {
        this.editcommiteevecreport.ApiKey = this.ApiKey;
        this.commiteevecpros.ApiKey = this.ApiKey;
        this.commiteevecpros.commiteevecproviceID = this.editcommiteevecreport.commiteevecreportproviceID;
        this.commiteevecpros.commiteeVecProYear = this.editcommiteevecreport.commiteevecreportYear;
        this.commiteevecpros.commiteeVecProTime = this.editcommiteevecreport.commiteevecreportTime;
        this.commiteevecpros.commiteevecproStatus = "0";

        let result;

        result = await this.$http.post(
          "commiteevecreport.delete.php",
          this.editcommiteevecreport
        );

        result = await this.$http.post(
          "commiteevecpro.update.status.php",
          this.commiteevecpros
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.commiteevecreportsQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ลบข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletecommiteevecreportdialog = false;
      }
    },
    async commiteevecreportConfirm() {
      this.Comfirmcommiteevecreportdialog = true;
    },
    async confirmcommiteevecreportubmit() {
      if (this.$refs.comfirmcommiteevecreportform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;

        this.commiteevecreportStatusC.ApiKey = this.ApiKey;
        this.commiteevecreportStatusC.commiteevecreportproviceID =
          userSession.province_ID;
        this.commiteevecreportStatusC.commiteevecreportYear = this.periods.period_vecprovince_year;
        this.commiteevecreportStatusC.commiteevecreportTime = this.periods.period_vecprovince_times;
        this.commiteevecreportStatusC.commiteevecreportStatus = "1";

        this.commiteevecreportStatusRP.ApiKey = this.ApiKey;
        this.commiteevecreportStatusRP.commiteevecreportproviceID =
          userSession.province_ID;
        this.commiteevecreportStatusRP.commiteevecreportYear = this.periods.period_vecprovince_year;
        this.commiteevecreportStatusRP.commiteevecreportTime = this.periods.period_vecprovince_times;
        this.commiteevecreportStatusRP.commiteevecreportStatus = "1";
        this.commiteevecreportStatusRP.commiteevecreportDateTime = this.date_today_cal;

        let result = await this.$http.post(
          "commiteevecreport.update.status.php",
          this.commiteevecreportStatusC
        );

        let result_report = await this.$http.post(
          "commiteevecreport.insert.php",
          this.commiteevecreportStatusRP
        );
        if (result.data.status == true && result_report.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.commiteevecreportsQueryAll();
          await this.commiteevecreportQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.Comfirmcommiteevecreportdialog = false;
      }
    },
    async UpdateFilecommiteevecreport() {
      let result = "";
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let uploaded = null;
      this.updateFilecommiteevecreports.ApiKey = this.ApiKey;
      this.updateFilecommiteevecreports.commiteevecreportproviceID =
        userSession.province_ID;
      this.updateFilecommiteevecreports.commiteevecreportYear = this.periods.period_vecprovince_year;
      this.updateFilecommiteevecreports.commiteevecreportTime = this.periods.period_vecprovince_times;
      this.updateFilecommiteevecreports.commiteevecreportFileDate = this.date_today_cal;

      if (this.$refs.Updatecommiteevecreport_transferform.validate()) {
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.updateFilecommiteevecreports.commiteevecreportproviceID +
            this.periods.period_vecprovince_year +
            this.periods.period_vecprovince_times +
            "committeevp.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updateFilecommiteevecreports.commiteevecreportFile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      result = await this.$http.post(
        "commiteevecreport.update.php",
        this.updateFilecommiteevecreports
      );
      ;
      if (result.data.status || uploaded) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.commiteevecreportsQueryAll();
        await this.commiteevecreportQuery();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    }
  },

  computed: {
    checkEventAss() {
      let data = this.commiteevecreports;
      let sum = 0;
      data.forEach(value => {
        sum = sum + parseInt(value[4]);
      });
      return sum;
    },

    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "#1D386A";
    },
    date_today_cal() {
      let today = new Date();
      return today;
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
